import { useForm } from 'react-hook-form';

import { DialogButtons } from 'components/dialog/styles';
import Modal from 'components/modal';
import Button from 'components/primitives/button';

import { DirectLinkURLs } from '../../types';

import { DirectLinksFormCheckbox, DirectLinksFormContainer, DirectLinksFormField } from './styles';

const AWAY_URL = 'awayUrl';

export const AWAY_URL_AWAY_HOME_SWAPPED = 'awayUrlAwayHomeSwapped';
const HOME_URL = 'homeUrl';

export const HOME_URL_AWAY_HOME_SWAPPED = 'homeUrlAwayHomeSwapped';
const AWAY_HOME_SWAPS = 'awayHomeSwaps';

type Props = {
    open: boolean;
    onClose: () => void;
    onSubmit: (urls: FormData) => void;
    urls: DirectLinkURLs | undefined;
    awayName?: string;
    homeName?: string;
};

export type FormData = {
    [AWAY_URL]: string | null;
    [HOME_URL]: string | null;
    [AWAY_HOME_SWAPS]: string[];
};

const DirectLinksForm = ({ open, onClose, onSubmit, urls, awayName, homeName }: Props) => {
    const { register, handleSubmit, reset } = useForm<FormData>({
        defaultValues: {
            awayUrl: urls?.awayUrl,
            homeUrl: urls?.homeUrl,
            awayHomeSwaps: [
                ...(urls?.awayUrlAwayHomeSwapped ? [AWAY_URL_AWAY_HOME_SWAPPED] : []),
                ...(urls?.homeUrlAwayHomeSwapped ? [HOME_URL_AWAY_HOME_SWAPPED] : []),
            ],
        },
    });

    const handleCancel = () => {
        reset();
        onClose();
    };

    return (
        <Modal
            open={open}
            onBackDropClick={onClose}
            style={{ minWidth: '15.5rem', padding: '1.5rem' }}
        >
            <DirectLinksFormContainer onSubmit={handleSubmit(onSubmit)}>
                <span className="title">Game links:</span>
                <div className="fields">
                    <DirectLinksFormField>
                        <label htmlFor={AWAY_URL}>{`${awayName || 'Away'} site:`}</label>
                        <input {...register(AWAY_URL)} />
                        <DirectLinksFormCheckbox>
                            <input
                                type="checkbox"
                                value={AWAY_URL_AWAY_HOME_SWAPPED}
                                {...register(AWAY_HOME_SWAPS)}
                            />
                            <label htmlFor={AWAY_URL_AWAY_HOME_SWAPPED}>
                                Change Home/Away Designation
                            </label>
                        </DirectLinksFormCheckbox>
                    </DirectLinksFormField>
                    <DirectLinksFormField>
                        <label htmlFor={HOME_URL}>{`${homeName || 'Home'} site:`}</label>
                        <input {...register(HOME_URL)} />
                        <DirectLinksFormCheckbox>
                            <input
                                type="checkbox"
                                value={HOME_URL_AWAY_HOME_SWAPPED}
                                {...register(AWAY_HOME_SWAPS)}
                            />
                            <label htmlFor={HOME_URL_AWAY_HOME_SWAPPED}>
                                Change Home/Away Designation
                            </label>
                        </DirectLinksFormCheckbox>
                    </DirectLinksFormField>
                </div>
                <DialogButtons>
                    <Button onClick={handleCancel} noBorder>
                        Cancel
                    </Button>
                    <Button className="submit" type="submit">
                        Save Changes
                    </Button>
                </DialogButtons>
            </DirectLinksFormContainer>
        </Modal>
    );
};

export default DirectLinksForm;
