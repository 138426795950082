import Loading from 'components/loading';

import { LoadingPageContainer } from './styles';

const LoadingPage = () => {
    return (
        <LoadingPageContainer>
            <Loading />
        </LoadingPageContainer>
    );
};

export default LoadingPage;
