import * as React from 'react';

import { TeamSorting } from 'api/types/bff';
import { Option } from 'types/controls';

const DEFAULT_OPTIONS: Option<TeamSorting>[] = [
    { label: 'Alphabetical (A-Z)', value: TeamSorting.ALPHABETICAL_ASC },
    { label: 'Alphabetical (Z-A)', value: TeamSorting.ALPHABETICAL_DESC },
];

export function useTeamSortingOptions(current: TeamSorting): Option<TeamSorting>[] {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
                selected: current === option.value,
            })),
        [current],
    );
}
