import { useMutation, useQueryClient } from 'react-query';

import API from 'api';
import client from 'api/client';
import { Mappings } from 'api/types/bff';
import { LeagueType, SourceId } from 'types';

import { MappingKeys } from './queries';

async function updateMappings({
    league,
    source,
    mappingType,
    mapping,
}: Mappings.UpdateMappingsParams) {
    return client
        .put(`${API.BFF_API}/${league}/mappings/source/${source}/manual/${mappingType}`, mapping)
        .then((response) => response.data);
}

type Parameters = {
    league: LeagueType;
    source: SourceId;
    mappingType: Mappings.MappingType;
    id?: string;
};

export function useMappingsMutation(parameters: Parameters) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (mapping: Mappings.UpdateMappingsParams['mapping']) =>
            updateMappings({ ...parameters, mapping }),
        onSuccess: () => {
            queryClient.invalidateQueries(
                MappingKeys.mappings({
                    league: parameters.league,
                    source: parameters.source,
                    mappingType: parameters.mappingType,
                    id: parameters.id,
                }),
            );
            queryClient.invalidateQueries(
                MappingKeys.mappingsStatsPartial({
                    league: parameters.league,
                    mappingType: parameters.mappingType,
                    id: parameters.id,
                }),
            );
        },
        onError: console.error,
        retry: 2,
    });
}
