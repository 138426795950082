import { IconDecorated } from 'components/icons';
import Tooltip from 'components/primitives/tooltip';

type Props = {
    onClick: () => void;
    isLoading?: boolean;
};

const FlagRefresh = ({ onClick, isLoading }: Props) => {
    return (
        <Tooltip title="Click to update flag" disabled={isLoading} disableInteractive>
            <div>
                <IconDecorated
                    sizeRem={1.25}
                    name="Refresh"
                    onClick={onClick}
                    data-testid="boxscore-refresh"
                />
            </div>
        </Tooltip>
    );
};

export default FlagRefresh;
