import styled from '@emotion/styled';

export const FlagContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: inherit;
    padding-left: 5.3rem;

    & > .line {
        position: relative;
        bottom: 0.0625rem;
        margin: 0;
        height: 0.0625rem;
        border-width: 0;
        background-color: var(--color-sidebar-hover-bg);
    }
`;

export const SourceSelectorContainer = styled.div<{ paddingTop?: boolean }>`
    padding: 0 0 1rem;

    ${(props) => (props.paddingTop ? 'padding-top: 1rem' : '')}
`;

export const Header = styled.div`
    display: flex;
    padding: 0;
    gap: 0.5rem;
    justify-content: flex-end;

    & > .actions {
        display: flex;
        gap: 0.5rem;
        cursor: pointer;
    }
`;
