import React from 'react';
import { Route } from 'type-route';

import { Mappings } from 'api/types/bff';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import ControlBar from 'components/control-bar';
import { IconDecorated } from 'components/icons';
import { MappingColumns } from 'components/mapping/mapping-columns';
import Tooltip from 'components/primitives/tooltip';
import View from 'components/view';
import ViewHeader from 'components/view-header';
import { useChangeParams } from 'hooks/useChangeParams';
import useDateSnapshot from 'hooks/useDateSnapshot';
import { ExternalSourceId, SourceId } from 'types';

import TeamsTabMenu from '../teams/components/teams-tab-menu';
import useTeams from '../teams/hooks/useTeams';

type Props = {
    route: Route<typeof routes.teamsMappings>;
};

const TeamsMappings = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);
    const source = route.params.source ?? leagueDetails.sources[0];

    const divisionAlias = !route.params.division
        ? leagueDetails.teamPageDefaultDivision
        : route.params.division === 'all'
        ? undefined
        : route.params.division;

    const teamsQuery = useTeams(
        route.params.league,
        route.params.seasonYear,
        route.params.seasonType,
        source,
        route.params.sorting,
        route.params.status,
        divisionAlias,
    );

    const updateDate = useDateSnapshot(teamsQuery.dataUpdatedAt);

    const handleParamsChange = useChangeParams(route);
    const handleSourceChange = React.useCallback(
        (sourceId: SourceId) => handleParamsChange({ source: sourceId as ExternalSourceId }),
        [handleParamsChange],
    );

    return (
        <View route={route} size="large">
            <ViewHeader
                iconElement={
                    <IconDecorated sizeRem={1.5} paletteColor="blue300" name="GroupWorkRounded" />
                }
                titleText="Teams"
            >
                <ControlBar spacing="small">
                    <Tooltip title="Last time the teams were pulled from the system">
                        <ControlBar.Text>Updated {updateDate}</ControlBar.Text>
                    </Tooltip>
                    <Tooltip
                        disabled={teamsQuery.isFetching}
                        disableInteractive
                        title="Click to refresh the teams"
                    >
                        <ControlBar.Button
                            disabled={teamsQuery.isFetching}
                            onClick={() => teamsQuery.refetch()}
                        >
                            {teamsQuery.isFetching ? 'Refreshing...' : 'Refresh'}
                        </ControlBar.Button>
                    </Tooltip>
                </ControlBar>
            </ViewHeader>
            <TeamsTabMenu route={route} />
            <MappingColumns
                league={route.params.league}
                source={source}
                sources={leagueDetails.sources}
                onSourceChange={handleSourceChange}
                type={Mappings.MappingType.TEAMS}
            />
        </View>
    );
};

export default TeamsMappings;
