import leaguesDetails from 'constants/leagues-details';
import { GamesGroupingStrategy, LeagueType } from 'types';

export default function useGamesGroupingStrategyLabel(league: LeagueType) {
    const strategy = leaguesDetails.find((d) => d.league === league)?.filters.gamesGrouping;

    switch (strategy) {
        case GamesGroupingStrategy.DAILY:
            return 'Daily Games';
        case GamesGroupingStrategy.WEEKLY:
            return 'Weekly Games';
        default:
            return 'Games';
    }
}
