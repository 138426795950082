import * as React from 'react';

import { FourZeroFour } from '../components/four-zero-four';

import { useRoute, routes } from './router';

const Unauthenticated = React.lazy(() => import('../modules/unauthenticated'));

const UnauthenticatedApp = () => {
    const route = useRoute();

    React.useEffect(() => {
        if (route.name !== false && route.name !== 'home') {
            routes.home().replace();
        }
    }, [route]);

    return (
        <React.Suspense fallback={<div>loading..</div>}>
            {route.name === 'home' && <Unauthenticated />}
            {false === route.name && <FourZeroFour />}
        </React.Suspense>
    );
};

export default UnauthenticatedApp;
