import { StatusCodes } from 'http-status-codes';
import { useMutation, useQueryClient } from 'react-query';

import API from 'api';
import client from 'api/client';

import { AdminScheduleKeys, AdminScheduleParams } from './queries';

async function createSchedule({
    league,
    seasonYear,
    seasonType,
    id,
    week,
    scheduleType,
}: Readonly<AdminScheduleParams>) {
    const searchParams = new URLSearchParams({
        ...(week && { week }),
    });

    return client.post(
        `${API.BFF_API}/${league}/schedule/${scheduleType}/${seasonYear}/${seasonType}/${id}?${searchParams}`,
    );
}

function useAdminScheduleMutation() {
    const queryClient = useQueryClient();

    return useMutation(createSchedule, {
        onSuccess: ({ status }, params) => {
            if (status === StatusCodes.OK) {
                queryClient.refetchQueries(AdminScheduleKeys.schedule(params));
            }
        },
        onError: async (error) => {
            console.error(error);
        },
        retry: 2,
    });
}

export default useAdminScheduleMutation;
