import * as React from 'react';
import { Route } from 'type-route';

import { Mappings } from 'api/types/bff';
import { MappingType } from 'api/types/mapper';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';
import { useMappingsStatsBySourcesQuery } from 'hooks/mapping/useMappingsStatsBySourcesQuery';
import { SourceId } from 'types';

const MAPPINGS_TYPE_LABEL_MAP: Record<MappingType, string> = {
    [MappingType.PLAYER]: 'Players',
    [MappingType.SCHEDULE]: 'Schedules',
    [MappingType.TEAM]: 'Teams',
};

type Props = {
    route: Route<typeof routes.teamsTeamMapping>;
    source: SourceId;
};

export const NavigationMapping = (props: Props) => {
    const { sources } = useLeagueDetails(props.route.params.league);

    const { data: playersStats } = useMappingsStatsBySourcesQuery({
        league: props.route.params.league,
        sources,
        mappingType: Mappings.MappingType.PLAYERS,
        id: props.route.params.id,
    });

    const { data: schedulesStats } = useMappingsStatsBySourcesQuery({
        league: props.route.params.league,
        sources,
        mappingType: Mappings.MappingType.SCHEDULES,
        id: props.route.params.id,
    });

    const unmappedPlayers = React.useMemo(
        () => Object.values(playersStats ?? {}).reduce((acc, { unmapped }) => acc + unmapped, 0),
        [playersStats],
    );

    const unmappedSchedules = React.useMemo(
        () => Object.values(schedulesStats ?? {}).reduce((acc, { unmapped }) => acc + unmapped, 0),
        [schedulesStats],
    );

    const handleTabClick = React.useCallback(
        (type: Mappings.MappingType) =>
            routes
                .teamsTeamMapping({
                    league: props.route.params.league,
                    seasonType: props.route.params.seasonType,
                    seasonYear: props.route.params.seasonYear,
                    id: props.route.params.id,
                    type,
                })
                .push(),
        [props.route.params],
    );

    return (
        <TabMenu>
            <TabButton
                active={props.route.params.type === Mappings.MappingType.PLAYERS}
                dotted={unmappedPlayers > 0}
                key={MappingType.PLAYER}
                onClick={() => handleTabClick(Mappings.MappingType.PLAYERS)}
            >
                {MAPPINGS_TYPE_LABEL_MAP[MappingType.PLAYER]}
            </TabButton>
            <TabButton
                active={props.route.params.type === Mappings.MappingType.SCHEDULES}
                dotted={unmappedSchedules > 0}
                key={MappingType.SCHEDULE}
                onClick={() => handleTabClick(Mappings.MappingType.SCHEDULES)}
            >
                {MAPPINGS_TYPE_LABEL_MAP[MappingType.SCHEDULE]}
            </TabButton>
        </TabMenu>
    );
};
