import styled from '@emotion/styled';

export const PopoverContainer = styled.div`
    display: flex;
`;

export const ExternalLinksPlaceholder = styled.div`
    color: var(--color-text);
    width: 100%;
`;

export const ExternalLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 18rem;
    min-width: 6rem;
    min-height: 4rem;
    overflow-y: auto;
    color: var(--color-text);
    background-color: var(--color-background);
    border-radius: 0.2rem;
    border: 0.0625rem solid var(--color-dropdown-border);
    padding: 0.25rem 0;
    margin: 0;
    font-size: 0.75rem;
    text-transform: uppercase;

    & .loading,
    .label,
    a {
        padding: 0.25rem 0.4375rem;
    }

    & .loading {
        overflow: hidden;
    }

    & a {
        display: block;
        cursor: pointer;
        text-decoration: none;
        color: var(--color-text);
        width: 100%;
    }

    & a:hover {
        font-weight: bold;
        text-decoration: underline;
    }
`;
