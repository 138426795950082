import * as React from 'react';

import { Mappings } from 'api/types/bff';
import { useCurrentSeason } from 'app/useCurrentSeason';
import useLeagueDetails from 'app/useLeagueDetails';
import { useMappingsStatsBySourcesQuery } from 'hooks/mapping/useMappingsStatsBySourcesQuery';
import { StatsResponse } from 'hooks/useFlagStats/types';
import { useGetFlagStats } from 'hooks/useFlagStats/useGetFlagStats';
import { LeagueType } from 'types';

export interface TeamNavigationStats {
    stats: number;
    roster: number;
    schedule: number;
    mapping: number;
    seasonal: number;
    standings: number;
    polls: number;
    admin: number;
}

export default function useNavigationStats(league: LeagueType, teamSrusId: string) {
    const currentSeason = useCurrentSeason();
    const {
        data: flagStats,
        isLoading: isFlagStatsLoading,
        error: flagStatsError,
    } = useGetFlagStats({ ...currentSeason, contextObjectId: teamSrusId });

    const { sources } = useLeagueDetails(league);

    const { data: playersStats } = useMappingsStatsBySourcesQuery({
        league,
        sources,
        mappingType: Mappings.MappingType.PLAYERS,
        id: teamSrusId,
    });

    const { data: schedulesStats } = useMappingsStatsBySourcesQuery({
        league,
        sources,
        mappingType: Mappings.MappingType.SCHEDULES,
        id: teamSrusId,
    });

    const unmappedPlayers = React.useMemo(
        () => Object.values(playersStats ?? {}).reduce((acc, { unmapped }) => acc + unmapped, 0),
        [playersStats],
    );

    const unmappedSchedules = React.useMemo(
        () => Object.values(schedulesStats ?? {}).reduce((acc, { unmapped }) => acc + unmapped, 0),
        [schedulesStats],
    );

    return React.useMemo(() => {
        const navigationStats: TeamNavigationStats = {
            stats: 0,
            roster: 0,
            schedule: 0,
            mapping: 0,
            seasonal: 0,
            standings: 0,
            polls: 0,
            admin: 0,
        };

        if (flagStats && !isFlagStatsLoading && !flagStatsError) {
            setFlagsStats(flagStats, navigationStats);
        }

        navigationStats.mapping = unmappedPlayers + unmappedSchedules;

        return navigationStats;
    }, [flagStats, flagStatsError, isFlagStatsLoading, unmappedPlayers, unmappedSchedules]);
}

function setFlagsStats(flagStats: StatsResponse, navigationStats: TeamNavigationStats) {
    const flagTypeStats: Record<string, number> = Object.keys(flagStats).reduce<
        Record<string, number>
    >((prev, cur) => {
        return {
            ...prev,
            [cur]: 0,
        };
    }, {});

    for (const flagType of Object.keys(flagStats)) {
        for (const contextType of Object.keys(flagStats[flagType])) {
            for (const source of Object.keys(flagStats[flagType][contextType])) {
                flagTypeStats[flagType] += flagStats[flagType][contextType][source]['CREATED'] ?? 0;
            }
        }
    }

    navigationStats.stats = flagTypeStats['boxscore'] ?? 0;
    navigationStats.roster = flagTypeStats['roster'] ?? 0;
    navigationStats.schedule = flagTypeStats['schedule'] ?? 0;
    navigationStats.seasonal = flagTypeStats['seasonal'] ?? 0;
    navigationStats.standings = flagTypeStats['standings'] ?? 0;
    navigationStats.polls = flagTypeStats['polls'] ?? 0;
}
