import { Team } from 'api/types/leagues';
import useTeamCrest from 'app/hooks/useTeamCrest';
import ControlBar from 'components/control-bar';
import { IconDecorated } from 'components/icons';
import Tooltip from 'components/primitives/tooltip';
import SportradarImage from 'components/sr-logo';
import ViewHeader from 'components/view-header';

type Props = {
    isRefreshing?: boolean;
    onBackButtonClick?: () => void;
    onRefreshClick?: () => void;
    team: Team;
    updateDate: string;
};

const TeamHeader = ({
    isRefreshing = false,
    onBackButtonClick,
    onRefreshClick,
    team: { market, name, srGlobalId },
    updateDate,
}: Props) => {
    const teamCrest = useTeamCrest(srGlobalId);

    return (
        <ViewHeader
            backButtonText="Teams"
            iconElement={
                <SportradarImage
                    src={teamCrest}
                    heightRem={3.125}
                    placeholder={<IconDecorated name="GroupWorkRounded" sizeRem={3.125} />}
                />
            }
            iconFormat="standalone"
            titleText={`${market} ${name}`}
            onBackButtonClick={onBackButtonClick}
        >
            <ControlBar spacing="small">
                <Tooltip title="Last time the data was pulled from the system">
                    <ControlBar.Text>Updated {updateDate}</ControlBar.Text>
                </Tooltip>
                {onRefreshClick && (
                    <Tooltip
                        disabled={isRefreshing}
                        disableInteractive
                        title="Click to refresh the data"
                    >
                        <ControlBar.Button disabled={isRefreshing} onClick={onRefreshClick}>
                            {isRefreshing ? 'Refreshing...' : 'Refresh'}
                        </ControlBar.Button>
                    </Tooltip>
                )}
            </ControlBar>
        </ViewHeader>
    );
};

export default TeamHeader;
