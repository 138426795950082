import * as React from 'react';

import { Admin } from 'api/types/bff';
import ExternalLinks from 'components/external-links';
import { FlagContainer } from 'components/flag-accordion/styles';
import FlagRefresh from 'components/flag-refresh';
import Loading from 'components/loading';
import SourceSelector from 'components/source-selector';
import { ValuesBySource } from 'components/source-selector/types';
import useAdminScheduleMutation from 'hooks/useAdminScheduleMutation';
import { SourceId } from 'types';

import { teamName } from '../../team-name';
import FlagContext from '../context';
import { AccordionContent } from '../default/styles';
import HeaderStandard from '../header-standard';
import { useTeamContext } from '../hooks/useTeamContext';
import { useTotalDiscrepanciesCount } from '../hooks/useTotalDiscrepanciesCount';
import DiscrepancyCategorized from '../seasonal/discrepancy-categorized';
import { FlagContentContainer, Header, SourceSelectorContainer } from '../styles';
import { FlagProps } from '../types';

const FlagStandings = ({ flag }: FlagProps) => {
    const {
        discrepancyBySources,
        activeSource,
        setActiveSource: handleSourceClick,
        activeDiscrepancies,
        areDiscrepanciesLoading,
        externalLinks,
        areExternalLinksLoading,
        isToggled,
        handleToggle,
    } = React.useContext(FlagContext);
    const sourceSelectorValues: ValuesBySource = discrepancyBySources
        .map((discrepancies) => ({
            source: discrepancies.source,
            value: discrepancies.discrepancies.length,
        }))
        .filter((value) => value.source !== SourceId.SR && value.value);

    const teamContext = useTeamContext(flag);
    const getFlagHeader = React.useCallback(() => {
        if (!teamContext) {
            return 'N/A';
        }

        return teamName(teamContext);
    }, [teamContext]);

    const totalDiscrepancies = useTotalDiscrepanciesCount(flag);

    const adminScheduleMutation = useAdminScheduleMutation();
    const handleRefreshClick = React.useCallback(() => {
        adminScheduleMutation.mutate({
            league: flag.league,
            seasonType: flag.seasonType,
            seasonYear: flag.seasonYear,
            id: teamContext?.srId!,
            scheduleType: Admin.ScheduleTypes.TeamStandings,
        });
    }, [adminScheduleMutation, flag.league, flag.seasonType, flag.seasonYear, teamContext?.srId]);

    return (
        <FlagContainer data-testid="flag-standings-container">
            <HeaderStandard
                text={getFlagHeader()}
                handleToggle={handleToggle}
                isToggled={isToggled}
                iconName="HorizontalSplitRounded"
                iconColor="blue400"
                flagType={flag.flagType.name}
                count={totalDiscrepancies}
                createdAt={flag.createdAt}
            />
            <AccordionContent visible={isToggled} data-testid="accordion-content">
                <FlagContentContainer>
                    <Header>
                        <div className="actions">
                            <FlagRefresh
                                onClick={handleRefreshClick}
                                isLoading={adminScheduleMutation.isLoading}
                            />
                            <ExternalLinks
                                isLoading={areExternalLinksLoading}
                                linksInfo={externalLinks}
                            />
                        </div>
                    </Header>
                    {areDiscrepanciesLoading ? (
                        <Loading mt={2} />
                    ) : (
                        <>
                            <SourceSelectorContainer>
                                <SourceSelector
                                    valuesBySource={sourceSelectorValues}
                                    onSourceClick={handleSourceClick}
                                    activeSource={activeSource}
                                />
                            </SourceSelectorContainer>
                            <hr className="line" />
                            <DiscrepancyCategorized
                                title="Team"
                                discrepancies={activeDiscrepancies}
                                type="team"
                            />
                        </>
                    )}
                </FlagContentContainer>
            </AccordionContent>
        </FlagContainer>
    );
};

export default FlagStandings;
