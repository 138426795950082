import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { Mappings } from 'api/types/bff';

import { MappingKeys } from './queries';

const get = async (url: string) => {
    const response = await client.get<Mappings.GetMappingsStatsResponse>(url);

    return response.data;
};

async function getMappingsStats({
    league,
    sources,
    mappingType,
    id,
}: Mappings.GetMappingsStatsParams): Promise<Mappings.GetMappingsStatsResponse> {
    const searchParams = new URLSearchParams({
        sources: sources.join(','),
    });

    switch (mappingType) {
        case Mappings.MappingType.TEAMS:
            return get(`${API.BFF_API}/${league}/mappings/teams/stats?${searchParams}`);
        case Mappings.MappingType.SCHEDULES:
            return get(
                `${API.BFF_API}/${league}/mappings/team/${id}/schedules/stats?${searchParams}`,
            );
        case Mappings.MappingType.PLAYERS:
            return get(
                `${API.BFF_API}/${league}/mappings/team/${id}/players/stats?${searchParams}`,
            );
    }
}

export function useMappingsStatsBySourcesQuery(params: Mappings.GetMappingsBySourceParams) {
    return useQuery({
        queryKey: MappingKeys.mappingsStats(params),
        queryFn: () => getMappingsStats(params),
        onError: console.error,
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000, // 1 min
        cacheTime: 5 * 60 * 1000, // 5 min
    });
}
