import {
    Data,
    DndContext,
    DragEndEvent,
    PointerSensor,
    pointerWithin,
    useSensor,
} from '@dnd-kit/core';
import { useSnackbar } from 'notistack';
import * as React from 'react';

import { Mappings } from 'api/types/bff';
import { MappingEntity, MappingStatus } from 'api/types/mapper';
import useLeagueDetails from 'app/useLeagueDetails';
import Loading from 'components/loading';
import Modal from 'components/modal';
import SourceSelector from 'components/source-selector';
import Stat from 'components/stat';
import Stats from 'components/stats';
import { useMappingsMutation } from 'hooks/mapping/useMappingsMutation';
import { useMappingsQuery } from 'hooks/mapping/useMappingsQuery';
import { useMappingsStatsBySourcesQuery } from 'hooks/mapping/useMappingsStatsBySourcesQuery';
import { LeagueType, SourceId } from 'types';

import { CardMapping } from '../mapping-cards/card-mapping';
import { CardUnmapping } from '../mapping-cards/card-unmapping';
import { MappingColumn } from '../mapping-column';
import { MappingDraggable } from '../mapping-draggable';
import { MappingDroppable } from '../mapping-droppable';
import { MappingItem } from '../mapping-item';

import { MappingColumnsContainer } from './styles';

enum ActionType {
    MAPPING = 'mapping',
    UNMAPPING = 'unmapping',
    REMAPPING = 'remapping',
}

type Action = {
    type: ActionType;
    source: MappingEntity;
    target?: MappingEntity;
};

function getIsOverExternalContainer(activeData?: Data) {
    return activeData?.status !== MappingStatus.UNMAPPED;
}

function getIsOverSportradarContainer(activeData?: Data, overId?: string | number) {
    return activeData?.srId !== overId;
}

type Props = {
    league: LeagueType;
    onSourceChange: (source: SourceId) => void;
    source: SourceId;
    sources: SourceId[];
    teamId?: string;
    type: Mappings.MappingType;
};

export const MappingColumns = (props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });
    const { mappingSiteSources } = useLeagueDetails(props.league);

    const { data: statsBySource } = useMappingsStatsBySourcesQuery({
        league: props.league,
        sources: mappingSiteSources,
        mappingType: props.type,
        id: props.teamId,
    });

    const unmappedPerSource = React.useMemo(
        () =>
            mappingSiteSources.map((source) => ({
                source,
                value: statsBySource?.[source]?.unmapped || 0,
            })),
        [mappingSiteSources, statsBySource],
    );

    const aggregatedStats = statsBySource?.[props.source];

    const mappingsQuery = useMappingsQuery({
        league: props.league,
        source: props.source,
        mappingType: props.type,
        id: props.teamId,
    });

    const mappingsMutation = useMappingsMutation({
        league: props.league,
        source: props.source,
        mappingType: props.type,
        id: props.teamId,
    });

    const [action, setAction] = React.useState<Action>();

    const handleMapClick = React.useCallback(
        (entity: MappingEntity) => setAction({ type: ActionType.MAPPING, source: entity }),
        [setAction],
    );
    const handleRemapClick = React.useCallback(
        (entity: MappingEntity) => setAction({ type: ActionType.REMAPPING, source: entity }),
        [setAction],
    );
    const handleUnmapClick = React.useCallback(
        (entity: MappingEntity) => setAction({ type: ActionType.UNMAPPING, source: entity }),
        [setAction],
    );

    const handleMappingSubmit = React.useCallback(
        (id: string, srId?: string) => mappingsMutation.mutate({ id, srId: srId ?? null }),
        [mappingsMutation],
    );
    const handleMappingCancel = React.useCallback(() => setAction(undefined), [setAction]);

    const handleDragEnd = React.useCallback(
        (event: DragEndEvent) => {
            if (!event.active.data.current) {
                return;
            }

            if (
                props.type === Mappings.MappingType.SCHEDULES &&
                event.over?.data.current?.status !== MappingStatus.UNMAPPED
            ) {
                enqueueSnackbar('Cannot map to an already mapped game', { variant: 'error' });

                return;
            }

            if (
                event.over?.id === MappingStatus.UNMAPPED &&
                event.active.data.current?.status !== MappingStatus.UNMAPPED
            ) {
                setAction({
                    type: ActionType.UNMAPPING,
                    source: event.active.data.current as MappingEntity,
                });
            }

            if (
                event.over?.data.current?.source === SourceId.SR &&
                event.active.data.current?.srId !== event.over?.id
            ) {
                setAction({
                    type:
                        event.active.data.current.status === MappingStatus.UNMAPPED
                            ? ActionType.MAPPING
                            : ActionType.REMAPPING,
                    source: event.active.data.current as MappingEntity,
                    target: event.over?.data.current as MappingEntity,
                });
            }
        },
        [setAction, props.type, enqueueSnackbar],
    );

    React.useEffect(() => {
        setAction(undefined);
    }, [mappingsQuery.dataUpdatedAt]);

    const dropdownEntities = React.useMemo(() => {
        const entities =
            action?.source.source === SourceId.SR
                ? mappingsQuery?.data?.external ?? []
                : mappingsQuery?.data?.internal ?? [];

        if (props.type === Mappings.MappingType.SCHEDULES) {
            return entities.filter((entity) => entity.status === MappingStatus.UNMAPPED);
        }

        return entities;
    }, [
        action?.source.source,
        mappingsQuery?.data?.external,
        mappingsQuery?.data?.internal,
        props.type,
    ]);

    return (
        <MappingColumnsContainer>
            <div className="mapping-columns-header">
                <SourceSelector
                    activeSource={props.source}
                    onSourceClick={props.onSourceChange}
                    valuesBySource={unmappedPerSource}
                />
                <Stats data-testid="source-mappings-stats">
                    <Stat
                        label="Auto-mapped"
                        value={aggregatedStats?.automatic ?? 0}
                        iconKey="IgnoredFlag"
                        opacity={0.1}
                        color="black300"
                        paletteColor="black300"
                    />
                    <Stat
                        label="Manually Mapped"
                        value={aggregatedStats?.manual ?? 0}
                        iconKey="IgnoredFlag"
                        opacity={0.1}
                        color="black300"
                        paletteColor="black300"
                    />
                    <Stat
                        label="Unmapped"
                        value={aggregatedStats?.unmapped ?? 0}
                        iconKey="Flag"
                        opacity={0.05}
                        color="red500"
                        paletteColor="red600"
                    />
                </Stats>
            </div>
            {mappingsQuery.isLoading && <Loading />}
            {!mappingsQuery.isLoading && (
                <div className="mapping-columns-container">
                    <DndContext
                        collisionDetection={pointerWithin}
                        onDragEnd={handleDragEnd}
                        sensors={[sensor]}
                    >
                        <MappingColumn
                            title="External Mappings"
                            entities={mappingsQuery.data?.external ?? []}
                        >
                            {(entities) => (
                                <MappingDroppable
                                    className="mapping-columns-external-droppable"
                                    id={MappingStatus.UNMAPPED}
                                    getIsOver={getIsOverExternalContainer}
                                >
                                    {entities.map((entity) => (
                                        <MappingDraggable
                                            key={entity.id}
                                            data={entity}
                                            id={entity.id}
                                        >
                                            <MappingItem
                                                entity={entity}
                                                onMapClick={handleMapClick}
                                            />
                                        </MappingDraggable>
                                    ))}
                                </MappingDroppable>
                            )}
                        </MappingColumn>
                        <div className="mapping-columns-divider" />
                        <MappingColumn
                            title="Sportradar Mappings"
                            entities={mappingsQuery.data?.internal ?? []}
                            hasUnmappedCheckbox
                        >
                            {(entities) =>
                                entities.map((entity) => (
                                    <MappingDroppable
                                        key={entity.id}
                                        data={entity}
                                        id={entity.id}
                                        getIsOver={getIsOverSportradarContainer}
                                    >
                                        <MappingItem
                                            entity={entity}
                                            onMapClick={handleMapClick}
                                            onRemapClick={handleRemapClick}
                                            onUnmapClick={handleUnmapClick}
                                        />
                                    </MappingDroppable>
                                ))
                            }
                        </MappingColumn>
                    </DndContext>
                </div>
            )}
            <Modal
                open={!!action}
                style={{ padding: '1.5rem' }}
                onBackDropClick={handleMappingCancel}
            >
                {(action?.type === ActionType.MAPPING || action?.type === ActionType.REMAPPING) && (
                    <CardMapping
                        dropdownEntities={dropdownEntities}
                        dropdownInitialValue={action.target}
                        entity={action.source}
                        isSubmitting={mappingsMutation.isLoading || mappingsQuery.isFetching}
                        onCancelClick={handleMappingCancel}
                        onConfirmClick={handleMappingSubmit}
                        type={action.type}
                    />
                )}
                {action?.type === ActionType.UNMAPPING && (
                    <CardUnmapping
                        entity={action.source}
                        onCancelClick={handleMappingCancel}
                        onConfirmClick={handleMappingSubmit}
                        onRemapClick={handleRemapClick}
                        isSubmitting={mappingsMutation.isLoading || mappingsQuery.isFetching}
                    />
                )}
            </Modal>
        </MappingColumnsContainer>
    );
};
