import * as React from 'react';

import { BasicButton, DarkPrimary, Variant5 } from './styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

interface ButtonFactoryProps extends ButtonProps {
    children?: React.ReactNode;
    variant: 'basic' | 'dark-primary' | 'variant5';
}

const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonFactoryProps>>(
    ({ variant, ...rest }, ref) => {
        switch (variant) {
            case 'basic':
                return <BasicButton ref={ref} {...rest} />;
            case 'dark-primary':
                return <DarkPrimary ref={ref} {...rest} />;
            case 'variant5':
                return <Variant5 ref={ref} {...rest} />;
            default:
                throw new Error('This variant is not supported');
        }
    },
);

export default Button;
