import * as React from 'react';

import { Option } from 'types/controls';

import { IconDecorated } from '../icons';
import Popover from '../popover';

import { DropdownOptionsContainer, DropdownPlaceholder } from './styles';

export type DropdownValue = string | number | object | undefined;

type Props<T extends DropdownValue> = {
    onChange: (value: T) => void;
    options: Option<T>[];
};

const LeagueDropdown = <T extends DropdownValue>({ onChange, options }: Props<T>) => {
    const placeholderRef = React.useRef<HTMLDivElement>(null!);
    const selectedOption = options.find((option) => option.selected);

    const placeholder = (
        <DropdownPlaceholder ref={placeholderRef} data-testid="dropdown">
            <span className="dropdown-label">{selectedOption?.label}</span>
            <div className="dropdown-icon-container">
                <IconDecorated sizeRem={1.5} name="ArrowDropDown" />
            </div>
        </DropdownPlaceholder>
    );

    const handleChange = React.useCallback(
        (value, onClose) => {
            onChange(value);
            onClose();
        },
        [onChange],
    );

    const anchorVerticalOrigin = placeholderRef.current
        ? placeholderRef.current.offsetHeight + 2
        : 'bottom';

    return (
        <Popover
            placeholder={placeholder}
            anchorOrigin={{ horizontal: 'left', vertical: anchorVerticalOrigin }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
            {(onClose) => {
                return (
                    <DropdownOptionsContainer
                        data-testid="dropdown-options"
                        placeholderWidth={placeholderRef?.current?.offsetWidth}
                    >
                        {options.map((option) => (
                            <div
                                key={option.label}
                                className="option-item"
                                onClick={() => handleChange(option.value, onClose)}
                            >
                                {option.label}
                            </div>
                        ))}
                    </DropdownOptionsContainer>
                );
            }}
        </Popover>
    );
};

export default LeagueDropdown;
