import { QueryClient } from 'react-query';

import { Game } from 'api/types/bff';

import { LiveFlags } from '../../api/types/live-flags';
import { GameKeys } from '../../modules/game-boxscores/views/game/hooks/queries';
import { WSBackendEvent } from '../types';

export enum SystemEventTopicTypes {
    FLAGS_UPDATE = 'FLAGS_UPDATE',
}

export const liveFlagsProcessor = async (
    queryClient: QueryClient,
    payload: object,
): Promise<void> => {
    const liveFlagMessage = payload as WSBackendEvent<LiveFlags.LiveFlagWSUpdateEvent>;

    if (liveFlagMessage.type !== SystemEventTopicTypes.FLAGS_UPDATE) {
        console.warn('liveFlagsProcessor: unknown message type: %s', liveFlagMessage.type);

        return;
    }

    const pk = liveFlagMessage.payload.toDelete.pk;
    let [league, , , id] = pk.split('#');

    const toRemoveIds = liveFlagMessage.payload.toDelete.sk.map(
        (sk) => `${liveFlagMessage.payload.toDelete.pk}__${sk}`,
    );

    const queriesData = queryClient.getQueriesData<Game.LiveGameFlag>(
        GameKeys.gameShort(league, id),
    );

    for (const queryData of queriesData) {
        const [queryKey] = queryData;
        const [, , gameId, status, source, context, statName] = Array.from(queryKey);

        queryClient.setQueryData<Game.LiveGame | undefined>(queryKey, (oldData) => {
            if (!oldData) {
                return oldData;
            }

            const liveFlags = oldData.liveFlags.filter((flag) => !toRemoveIds.includes(flag.id));

            liveFlags.unshift(
                ...liveFlagMessage.payload.toPut.filter(
                    (flag) =>
                        !liveFlags.some((liveFlag) => liveFlag.id === flag.id) &&
                        flag.contextSrIds.game === gameId &&
                        flag.source === source &&
                        flag.status === status &&
                        (flag.context === String(context).replace('individual', 'player') ||
                            context === 'all') &&
                        (flag.name === statName || statName === 'all'),
                ),
            );

            return {
                ...oldData,
                liveFlags,
            };
        });
    }
};
