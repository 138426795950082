import { useMemo } from 'react';

import { Mappings } from 'api/types/bff';
import useLeagueDetails from 'app/useLeagueDetails';
import GameMappingIndicator from 'components/mapping-indicator';
import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';
import { useMappingsStatsBySourcesQuery } from 'hooks/mapping/useMappingsStatsBySourcesQuery';
import { LeagueType } from 'types';

import { ViewType } from '../../types';

type Props = {
    league: LeagueType;
    awayTeamId?: string;
    awayTeamGlobalId?: string | null;
    awayTeamName?: string;
    awayTeamAlias?: string;
    homeTeamId?: string;
    homeTeamGlobalId?: string | null;
    homeTeamName?: string;
    homeTeamAlias?: string;
    gameHasFinished?: boolean;
    externalSchedules: {
        id: string;
        source: string;
    }[];
    onTeamViewOpen: (teamId: string) => void;
    gameView: ViewType;
    onChange: (gameView: ViewType) => void;
};

const GameViewTabs = ({
    league,
    awayTeamId,
    awayTeamGlobalId,
    awayTeamName = 'Away',
    awayTeamAlias = 'Away',
    homeTeamId,
    homeTeamGlobalId,
    homeTeamName = 'Home',
    homeTeamAlias = 'Home',
    gameHasFinished,
    externalSchedules,
    onTeamViewOpen,
    gameView,
    onChange,
}: Props) => {
    const { sources } = useLeagueDetails(league);
    const { data: playersStatsAwayTeam } = useMappingsStatsBySourcesQuery({
        league,
        sources,
        mappingType: Mappings.MappingType.PLAYERS,
        id: awayTeamId as string,
    });

    const { data: playersStatsHomeTeam } = useMappingsStatsBySourcesQuery({
        league,
        sources,
        mappingType: Mappings.MappingType.PLAYERS,
        id: homeTeamId as string,
    });

    const unmappedPlayersAwayTeam = useMemo(
        () =>
            Object.values(playersStatsAwayTeam ?? {}).reduce(
                (acc, { unmapped }) => acc + unmapped,
                0,
            ),
        [playersStatsAwayTeam],
    );

    const unmappedPlayersHomeTeam = useMemo(
        () =>
            Object.values(playersStatsHomeTeam ?? {}).reduce(
                (acc, { unmapped }) => acc + unmapped,
                0,
            ),
        [playersStatsHomeTeam],
    );

    return (
        <TabMenu>
            <TabButton
                active={gameView === ViewType.LIVE_GAME}
                onClick={() => onChange(ViewType.LIVE_GAME)}
            >
                Live Game
            </TabButton>
            {gameHasFinished && (
                <TabButton
                    active={gameView === ViewType.POST_GAME}
                    onClick={() => onChange(ViewType.POST_GAME)}
                >
                    Post Game
                </TabButton>
            )}
            <div style={{ flexGrow: 1 }} />
            <GameMappingIndicator
                teams={[
                    {
                        srId: awayTeamId!,
                        globalId: awayTeamGlobalId!,
                        name: awayTeamName,
                        abbreviation: awayTeamAlias,
                    },
                    {
                        srId: homeTeamId!,
                        globalId: homeTeamGlobalId!,
                        name: homeTeamName,
                        abbreviation: homeTeamAlias,
                    },
                ]}
                externalSchedules={externalSchedules}
                onTeamViewOpen={onTeamViewOpen}
            />
            {awayTeamId && (
                <TabButton
                    active={gameView === ViewType.AWAY_MAPPINGS}
                    dotted={unmappedPlayersAwayTeam > 0}
                    onClick={() => onChange(ViewType.AWAY_MAPPINGS)}
                >
                    {`${awayTeamAlias} - Mapping`}
                </TabButton>
            )}
            {homeTeamId && (
                <TabButton
                    active={gameView === ViewType.HOME_MAPPINGS}
                    dotted={unmappedPlayersHomeTeam > 0}
                    onClick={() => onChange(ViewType.HOME_MAPPINGS)}
                >
                    {`${homeTeamAlias} - Mapping`}
                </TabButton>
            )}
        </TabMenu>
    );
};

export default GameViewTabs;
