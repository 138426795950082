import { Mappings } from 'api/types/bff';
export const MappingKeys = {
    mappings: ({ league, source, mappingType, id }: Mappings.GetMappingsParams) => {
        return [league, 'mappings', mappingType, id, source];
    },
    mappingsStatsPartial: ({
        league,
        mappingType,
        id,
    }: Omit<Mappings.GetMappingsStatsParams, 'sources'>) => {
        return [league, 'mappings', mappingType, id, 'stats'];
    },
    mappingsStats: ({ sources, ...parameters }: Mappings.GetMappingsStatsParams) => {
        return [...MappingKeys.mappingsStatsPartial(parameters), sources];
    },
};
