import { LeagueType, SourceId } from 'types';

import { Mappings } from './bff';

export enum MappingStatus {
    AUTOMATIC = 'automatic',
    MANUAL = 'manual',
    UNMAPPED = 'unmapped',
}

export enum MappingType {
    PLAYER = 'player',
    SCHEDULE = 'schedule',
    TEAM = 'team',
}

export function isMappingType(mappingType: unknown): mappingType is Mappings.MappingType {
    return Object.values(Mappings.MappingType).includes(mappingType as Mappings.MappingType);
}

export type MappingTeam = {
    id: string;
    srId?: string;
    name?: string;
    status?: MappingStatus;
    source: SourceId;
    mappings: MappingTeam[];
    type: MappingType.TEAM;
};

export type MappingSchedule = {
    id: string;
    srId?: string;
    name?: string;
    date?: string;
    stadium?: string;
    status?: MappingStatus;
    source: SourceId;
    mappings: MappingSchedule[];
    type: MappingType.SCHEDULE;
};

export type MappingPlayer = {
    id: string;
    srId?: string;
    name?: string;
    jersey?: number;
    position?: string;
    status?: MappingStatus;
    source: SourceId;
    mappings: MappingPlayer[];
    type: MappingType.PLAYER;
};

export type MappingEntity = MappingSchedule | MappingPlayer | MappingTeam;

export type GetTeamMappingsParameters = {
    league: LeagueType;
    source: SourceId;
    teamId: string;
    status?: MappingStatus;
};

export type GetTeamMappingsStatsParameters = {
    league: LeagueType;
    teamId: string;
};
