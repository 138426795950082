import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';

import { Admin } from 'api/types/bff';
import ExternalLinks from 'components/external-links';
import Discrepancy from 'components/flag-accordion/discrepancy';
import { FlagContainer } from 'components/flag-accordion/styles';
import FlagRefresh from 'components/flag-refresh';
import FlagSystemEvents from 'components/flag-system-events';
import Loading from 'components/loading';
import Tooltip from 'components/primitives/tooltip';
import SourceSelector from 'components/source-selector';
import { ValuesBySource } from 'components/source-selector/types';
import useAdminScheduleMutation from 'hooks/useAdminScheduleMutation';
import { FlagType, flagTypeToContextType, SourceId } from 'types';

import BulkActions from '../../bulk-actions';
import useBulkActions from '../../bulk-actions/hooks/use-bulk-actions';
import { teamName } from '../../team-name';
import FlagContext from '../context';
import { AccordionContent } from '../default/styles';
import HeaderStandard from '../header-standard';
import { useGameContext } from '../hooks/useGameContext';
import { useSplitDiscrepanciesByHomeAway } from '../hooks/useSplitDiscrepanciesByHomeAway';
import { useTotalDiscrepanciesCount } from '../hooks/useTotalDiscrepanciesCount';
import { FlagContentContainer, Header, SourceSelectorContainer } from '../styles';
import { FlagProps } from '../types';

import HeaderInternal from './header-internal';
import {
    DiscrepanciesContainer,
    DiscrepancyRow,
    FlagDiscrepancies,
    GameContextInfo,
} from './styles';
import TeamDiscrepancies from './team-discrepancies';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const FlagBoxscore = ({ flag, moduleHeader, layout }: FlagProps) => {
    const {
        discrepancyBySources,
        activeSource,
        setActiveSource: handleSourceClick,
        activeDiscrepancies,
        areDiscrepanciesLoading,
        externalLinks,
        areExternalLinksLoading,
        isToggled,
        handleToggle,
    } = React.useContext(FlagContext);
    const splitDiscrepancies = useSplitDiscrepanciesByHomeAway(activeDiscrepancies);
    const sourceSelectorValues: ValuesBySource = discrepancyBySources
        .map((discrepancies) => ({
            source: discrepancies.source,
            value: discrepancies.discrepancies.length,
        }))
        .filter((value) => value.source !== SourceId.SR && value.value);

    const totalDiscrepancies = useTotalDiscrepanciesCount(flag);
    const gameContext = useGameContext(flag);

    const { checkedDiscrepancies, handleToggleCheckDiscrepancy, handleToggleAll, isActive } =
        useBulkActions([...splitDiscrepancies.rest].map((d) => d.id));

    const getGameOpponents = React.useCallback(
        () => `${teamName(gameContext?.awayTeam)} vs ${teamName(gameContext?.homeTeam)}`,
        [gameContext],
    );

    const headerText = React.useMemo(() => {
        if (totalDiscrepancies === 0) {
            return 'N/A';
        }

        if (!gameContext) {
            return 'N/A';
        }

        const gameDateInFriendlyFormat = dayjs(gameContext.scheduledDate).format(
            'MM-DD-YYYY hh:mm A z',
        );

        return `${getGameOpponents()}, ${gameDateInFriendlyFormat}`;
    }, [gameContext, getGameOpponents, totalDiscrepancies]);

    const ignoredCount = flag.discrepanciesSummary.ignoredCount;
    const flaggedCount = flag.discrepanciesSummary.createdCount;

    const adminScheduleMutation = useAdminScheduleMutation();
    const handleRefreshClick = React.useCallback(() => {
        adminScheduleMutation.mutate({
            league: flag.league,
            seasonType: flag.seasonType,
            seasonYear: flag.seasonYear,
            id: gameContext?.srId!,
            scheduleType: Admin.ScheduleTypes.Game,
        });
    }, [adminScheduleMutation, flag.league, flag.seasonType, flag.seasonYear, gameContext?.srId]);

    return (
        <FlagContainer data-testid="flag-boxscore-container">
            {moduleHeader ? (
                <HeaderInternal
                    text={headerText}
                    awayTeamSrId={gameContext?.awayTeam?.srGlobalId}
                    homeTeamSrId={gameContext?.homeTeam?.srGlobalId}
                    ignoredCount={ignoredCount}
                    createdCount={flaggedCount}
                    createdAt={flag.createdAt}
                    isToggled={isToggled}
                    handleToggle={handleToggle}
                />
            ) : (
                <HeaderStandard
                    text={headerText}
                    handleToggle={handleToggle}
                    isToggled={isToggled}
                    iconColor="green500"
                    iconName="WysiwygRounded"
                    flagType={flag.flagType.name}
                    count={totalDiscrepancies}
                    createdAt={flag.createdAt}
                />
            )}
            <AccordionContent visible={isToggled} data-testid="accordion-content">
                <FlagContentContainer>
                    <Header>
                        <GameContextInfo>
                            <div className="game-date">
                                {gameContext
                                    ? dayjs(gameContext.scheduledDate).format(
                                          'MM-DD-YYYY hh:mm A z',
                                      )
                                    : 'N/A'}
                            </div>
                            <div className="game-opponents">{getGameOpponents()}</div>
                        </GameContextInfo>
                        <div className="actions">
                            <FlagRefresh
                                onClick={handleRefreshClick}
                                isLoading={adminScheduleMutation.isLoading}
                            />
                            <ExternalLinks
                                isLoading={areExternalLinksLoading}
                                linksInfo={externalLinks}
                            />
                            <FlagSystemEvents
                                league={flag.league}
                                seasonYear={flag.seasonYear}
                                seasonType={flag.seasonType}
                                context={flagTypeToContextType(flag.flagType.key as FlagType)}
                                contextObjectId={flag.context.srId}
                            />
                        </div>
                    </Header>
                    {areDiscrepanciesLoading ? (
                        <Loading mt={2} />
                    ) : (
                        <>
                            <SourceSelectorContainer paddingTop>
                                <SourceSelector
                                    valuesBySource={sourceSelectorValues}
                                    onSourceClick={handleSourceClick}
                                    activeSource={activeSource}
                                />
                            </SourceSelectorContainer>
                            <hr className="line" />
                            {splitDiscrepancies.rest.length > 0 && (
                                <DiscrepanciesContainer>
                                    <div className="header">
                                        Game Discrepancies ({splitDiscrepancies.rest.length}):
                                    </div>
                                    <div className="list">
                                        <BulkActions
                                            isActive={isActive}
                                            checkedDiscrepancyIds={checkedDiscrepancies}
                                            onToggleSelect={handleToggleAll}
                                        />
                                        {splitDiscrepancies.rest.map((discrepancy) => (
                                            <DiscrepancyRow
                                                key={`d-row-${discrepancy.id}`}
                                                onClick={() =>
                                                    handleToggleCheckDiscrepancy(discrepancy.id)
                                                }
                                            >
                                                <Tooltip
                                                    disableInteractive
                                                    placement="left"
                                                    title={`Updated at ${dayjs(
                                                        discrepancy.updatedAt,
                                                    ).format('MM/DD/YY hh:mm A z')}`}
                                                >
                                                    <div />
                                                </Tooltip>
                                                <Discrepancy discrepancy={discrepancy} />
                                            </DiscrepancyRow>
                                        ))}
                                    </div>
                                </DiscrepanciesContainer>
                            )}
                            <FlagDiscrepancies layout={layout}>
                                <TeamDiscrepancies
                                    key="away-team-discrepancies"
                                    discrepancies={splitDiscrepancies.away}
                                    title={`Away Team (${
                                        splitDiscrepancies.away.teamDiscrepancies.length +
                                        splitDiscrepancies.away.playerDiscrepancies.length
                                    })`}
                                    alias={gameContext?.awayTeam?.alias ?? ''}
                                />
                                <TeamDiscrepancies
                                    key="home-team-discrepancies"
                                    discrepancies={splitDiscrepancies.home}
                                    title={`Home Team (${
                                        splitDiscrepancies.home.teamDiscrepancies.length +
                                        splitDiscrepancies.home.playerDiscrepancies.length
                                    })`}
                                    alias={gameContext?.homeTeam?.alias ?? ''}
                                />
                            </FlagDiscrepancies>
                        </>
                    )}
                </FlagContentContainer>
            </AccordionContent>
        </FlagContainer>
    );
};

export default FlagBoxscore;
