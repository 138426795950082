import { Route } from 'type-route';

import { groups, routes } from 'app/router';
import { useColorSchemeConfig } from 'app/theme/useColorScheme';
import useLeagueDetails from 'app/useLeagueDetails';
import useLeagueInfo from 'app/useLeagueInfo';
import useFlagStatsSummary from 'hooks/useFlagStats/useGetFlagStatsSummary';
import { useLeagueLogo } from 'hooks/useLeagueLogo';
import { FlagType, GamesGroupingStrategy } from 'types';

import MenuElement from './menu-element';
import { SidebarContainer } from './styles';

type Props = {
    route: Route<typeof groups.leagueFull>;
};

const SideBar = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);
    const leagueLogo = useLeagueLogo(leagueDetails.league);
    const leagueInfoQuery = useLeagueInfo({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
    });

    const [colorScheme] = useColorSchemeConfig();
    const flagStatsSummary = useFlagStatsSummary();

    const isDark = colorScheme === 'dark';

    return (
        <SidebarContainer data-testid="sidebar" isDark={isDark}>
            <span className="header">{leagueDetails.name}</span>
            <MenuElement
                key="daily-games"
                active={route.name === 'gameBoxscoresGames'}
                color="yellow500"
                iconName="Event"
                isDark={isDark}
                title="Daily games"
                onClick={
                    routes.gameBoxscoresGames({
                        league: route.params.league,
                        seasonType: route.params.seasonType,
                        seasonYear: route.params.seasonYear,
                    }).push
                }
            />
            <MenuElement
                key="dashboard"
                active={route.name === 'dashboard'}
                color="yellow500"
                counter={flagStatsSummary.all.created}
                iconName={leagueLogo}
                isDark={isDark}
                title={`All ${leagueDetails.nameAbbreviation}`}
                onClick={
                    routes.dashboard({
                        league: route.params.league,
                        seasonType: route.params.seasonType,
                        seasonYear: route.params.seasonYear,
                    }).push
                }
            />
            {leagueDetails.flagTypes.includes(FlagType.Standings) && (
                <MenuElement
                    key="standings"
                    active={route.name === 'standings'}
                    color="blue400"
                    counter={flagStatsSummary.standings.created}
                    iconName="HorizontalSplitRounded"
                    isDark={isDark}
                    title="Standings"
                    onClick={
                        routes.standings({
                            league: route.params.league,
                            seasonType: route.params.seasonType,
                            seasonYear: route.params.seasonYear,
                        }).push
                    }
                />
            )}
            {leagueDetails.flagTypes.includes(FlagType.Polls) && (
                <MenuElement
                    key="polls"
                    active={route.name === 'polls'}
                    isDark={isDark}
                    iconName="EqualizerRounded"
                    title="Poll Rankings"
                    counter={flagStatsSummary.polls.created}
                    color="purple"
                    onClick={
                        routes.polls({
                            league: route.params.league,
                            seasonType: route.params.seasonType,
                            seasonYear: route.params.seasonYear,
                            week: leagueInfoQuery.data?.currentWeek?.toString(),
                        }).push
                    }
                />
            )}
            {leagueDetails.flagTypes.includes(FlagType.Schedule) && (
                <MenuElement
                    key="schedule"
                    active={route.name === 'schedule'}
                    isDark={isDark}
                    iconName="CalendarTodayRounded"
                    title="Schedule"
                    counter={flagStatsSummary.schedule.created}
                    color="yellow600"
                    onClick={
                        routes.schedule({
                            league: route.params.league,
                            seasonType: route.params.seasonType,
                            seasonYear: route.params.seasonYear,
                            ...(leagueDetails.filters.scheduleGrouping ===
                                GamesGroupingStrategy.WEEKLY && {
                                week: leagueInfoQuery.data?.currentWeek?.toString(),
                            }),
                        }).push
                    }
                />
            )}
            {leagueDetails.flagTypes.includes(FlagType.Boxscore) && (
                <MenuElement
                    key="gameBoxscores"
                    active={
                        groups.leagueGameBoxscores.has(route) && route.name !== 'gameBoxscoresGames'
                    }
                    color="green500"
                    counter={flagStatsSummary.boxscore.created}
                    iconName="WysiwygRounded"
                    isDark={isDark}
                    title="Game Boxscores"
                    onClick={
                        routes.gameBoxscores({
                            league: route.params.league,
                            seasonType: route.params.seasonType,
                            seasonYear: route.params.seasonYear,
                            ...(leagueDetails.filters.boxscoreGrouping ===
                                GamesGroupingStrategy.WEEKLY && {
                                week: leagueInfoQuery.data?.currentWeek?.toString(),
                            }),
                        }).push
                    }
                />
            )}
            {leagueDetails.flagTypes.includes(FlagType.Seasonal) && (
                <MenuElement
                    key="seasonal"
                    active={route.name === 'seasonal'}
                    color="blue1000"
                    counter={flagStatsSummary.seasonal.created}
                    iconName="QueryBuilderRounded"
                    isDark={isDark}
                    title="Seasonal Statistics"
                    onClick={
                        routes.seasonal({
                            league: route.params.league,
                            seasonType: route.params.seasonType,
                            seasonYear: route.params.seasonYear,
                        }).push
                    }
                />
            )}
            <MenuElement
                key="teams"
                active={groups.leagueTeamsFull.has(route)}
                color="blue300"
                iconName="GroupWorkRounded"
                isDark={isDark}
                title="Teams"
                onClick={
                    routes.teams({
                        league: route.params.league,
                        seasonType: route.params.seasonType,
                        seasonYear: route.params.seasonYear,
                    }).push
                }
            />
            {leagueDetails.flagTypes.includes(FlagType.Roster) && (
                <MenuElement
                    key="rosters"
                    active={route.name === 'rosters'}
                    color="blue400"
                    counter={flagStatsSummary.roster.created}
                    iconName="PersonRounded"
                    isDark={isDark}
                    title="Rosters"
                    onClick={
                        routes.rosters({
                            league: route.params.league,
                            seasonType: route.params.seasonType,
                            seasonYear: route.params.seasonYear,
                        }).push
                    }
                />
            )}
        </SidebarContainer>
    );
};

export default SideBar;
