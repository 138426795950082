import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { Mappings } from 'api/types/bff';

import { MappingKeys } from './queries';

const get = async (url: string) => {
    const response = await client.get<Mappings.GetMappingsResponse>(url);

    return response.data;
};

async function getMappings({
    league,
    source,
    mappingType,
    id,
}: Mappings.GetMappingsParams): Promise<Mappings.GetMappingsResponse> {
    switch (mappingType) {
        case Mappings.MappingType.TEAMS:
            return get(`${API.BFF_API}/${league}/mappings/source/${source}/teams`);
        case Mappings.MappingType.SCHEDULES:
            return get(`${API.BFF_API}/${league}/mappings/source/${source}/team/${id}/schedules`);
        case Mappings.MappingType.PLAYERS:
            return get(`${API.BFF_API}/${league}/mappings/source/${source}/team/${id}/players`);
    }
}

export function useMappingsQuery(params: Mappings.GetMappingsParams) {
    return useQuery({
        queryKey: MappingKeys.mappings(params),
        queryFn: () => getMappings(params),
        onError: console.error,
        refetchOnWindowFocus: false,
        staleTime: 60 * 60 * 1000, // 1h
        cacheTime: 60 * 60 * 1000, // 1h
    });
}
