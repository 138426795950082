import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';

import { Admin } from 'api/types/bff';
import ExternalLinks from 'components/external-links';
import { teamName } from 'components/flag-accordion/team-name';
import FlagRefresh from 'components/flag-refresh';
import FlagSystemEvents from 'components/flag-system-events';
import Loading from 'components/loading';
import SourceSelector from 'components/source-selector';
import { ValuesBySource } from 'components/source-selector/types';
import useAdminScheduleMutation from 'hooks/useAdminScheduleMutation';
import { FlagType, flagTypeToContextType, SourceId } from 'types';

import { FlagContainer } from '../../styles';
import TeamDiscrepancies from '../boxscore/team-discrepancies';
import FlagContext from '../context';
import { AccordionContent } from '../default/styles';
import HeaderStandard from '../header-standard';
import { useGameContext } from '../hooks/useGameContext';
import { useSplitDiscrepanciesByHomeAway } from '../hooks/useSplitDiscrepanciesByHomeAway';
import { useTotalDiscrepanciesCount } from '../hooks/useTotalDiscrepanciesCount';
import { FlagContentContainer, Header, SourceSelectorContainer } from '../styles';
import { FlagProps } from '../types';

import GameDiscrepancies from './game-discrepancies';
import HeaderInternal from './header-internal';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const FlagSchedule = ({ flag, moduleHeader }: FlagProps) => {
    const {
        discrepancyBySources,
        activeSource,
        setActiveSource: handleSourceClick,
        activeDiscrepancies,
        areDiscrepanciesLoading,
        areExternalLinksLoading,
        externalLinks,
        isToggled,
        handleToggle,
    } = React.useContext(FlagContext);
    const splitDiscrepancies = useSplitDiscrepanciesByHomeAway(activeDiscrepancies);
    const totalDiscrepancies = useTotalDiscrepanciesCount(flag);
    const sourceSelectorValues: ValuesBySource = discrepancyBySources
        .map((discrepancies) => ({
            source: discrepancies.source,
            value: discrepancies.discrepancies.length,
        }))
        .filter((value) => value.source !== SourceId.SR && value.value);

    const gameContext = useGameContext(flag);

    const headerContent = React.useMemo(() => {
        const awayTeamName = teamName(gameContext?.awayTeam);
        const homeTeamName = teamName(gameContext?.homeTeam);
        const gameDate = gameContext?.scheduledDate
            ? dayjs(gameContext.scheduledDate).format('MM-DD-YYYY hh:mm A z')
            : 'N/A';

        return moduleHeader
            ? `${awayTeamName} @ ${homeTeamName} • ${gameDate}`
            : `${awayTeamName} vs ${homeTeamName}`;
    }, [gameContext?.awayTeam, gameContext?.homeTeam, gameContext?.scheduledDate, moduleHeader]);

    const adminScheduleMutation = useAdminScheduleMutation();
    const handleRefreshClick = React.useCallback(() => {
        adminScheduleMutation.mutate({
            league: flag.league,
            seasonType: flag.seasonType,
            seasonYear: flag.seasonYear,
            id: gameContext?.srId!,
            scheduleType: Admin.ScheduleTypes.TeamSchedule,
        });
    }, [adminScheduleMutation, flag.league, flag.seasonType, flag.seasonYear, gameContext?.srId]);

    return (
        <FlagContainer data-testid="flag-schedule-container">
            {moduleHeader ? (
                <HeaderInternal
                    awayTeamSrId={gameContext?.awayTeam?.srGlobalId}
                    homeTeamSrId={gameContext?.homeTeam?.srGlobalId}
                    text={headerContent}
                    ignoredCount={flag.discrepanciesSummary.ignoredCount}
                    createdCount={flag.discrepanciesSummary.createdCount}
                    createdAt={flag.createdAt}
                    handleToggle={handleToggle}
                    isToggled={isToggled}
                />
            ) : (
                <HeaderStandard
                    text={headerContent}
                    handleToggle={handleToggle}
                    isToggled={isToggled}
                    iconName="CalendarTodayRounded"
                    iconColor="yellow600"
                    flagType={flag.flagType.name}
                    count={totalDiscrepancies}
                    createdAt={flag.createdAt}
                />
            )}
            <AccordionContent visible={isToggled} data-testid="accordion-content">
                <FlagContentContainer>
                    <Header>
                        <div className="actions">
                            <FlagRefresh
                                onClick={handleRefreshClick}
                                isLoading={adminScheduleMutation.isLoading}
                            />
                            <ExternalLinks
                                isLoading={areExternalLinksLoading}
                                linksInfo={externalLinks}
                            />
                            <FlagSystemEvents
                                league={flag.league}
                                seasonYear={flag.seasonYear}
                                seasonType={flag.seasonType}
                                context={flagTypeToContextType(flag.flagType.key as FlagType)}
                                contextObjectId={flag.context.srId}
                            />
                        </div>
                    </Header>
                    {areDiscrepanciesLoading ? (
                        <Loading mt={2} />
                    ) : (
                        <>
                            <SourceSelectorContainer>
                                <SourceSelector
                                    valuesBySource={sourceSelectorValues}
                                    onSourceClick={handleSourceClick}
                                    activeSource={activeSource}
                                />
                            </SourceSelectorContainer>
                            <hr className="line" />
                            <GameDiscrepancies discrepancies={splitDiscrepancies.rest} />
                            <TeamDiscrepancies
                                key="away-team-discrepancies"
                                discrepancies={splitDiscrepancies.away}
                                title={`Away Team (${splitDiscrepancies.away.teamDiscrepancies.length})`}
                                alias={gameContext?.awayTeam?.alias ?? ''}
                            />
                            <TeamDiscrepancies
                                key="home-team-discrepancies"
                                discrepancies={splitDiscrepancies.home}
                                title={`Home Team (${splitDiscrepancies.home.teamDiscrepancies.length})`}
                                alias={gameContext?.homeTeam?.alias ?? ''}
                            />
                        </>
                    )}
                </FlagContentContainer>
            </AccordionContent>
        </FlagContainer>
    );
};

export default FlagSchedule;
