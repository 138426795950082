import * as React from 'react';
import { Route } from 'type-route';

import { Admin, FlagsSortingAttribute } from 'api/types/bff';
import { DiscrepancyStatusFilter } from 'api/types/flags';
import { groups } from 'app/router';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import FlagAccordion from 'components/flag-accordion';
import FlagStats from 'components/flag-stats';
import { Refresh, RefreshAction } from 'components/flag-stats/styles';
import FlagSystemEvents from 'components/flag-system-events';
import { IconDecorated } from 'components/icons';
import Loading from 'components/loading';
import Tooltip from 'components/primitives/tooltip';
import View from 'components/view';
import { useDiscrepancyStatusOptions } from 'hooks/options/useDiscrepancyStatusOptions';
import { useFlagsSortingOptions } from 'hooks/options/useFlagsSortingOptions';
import useAdminScheduleMutation from 'hooks/useAdminScheduleMutation';
import { useChangeParams } from 'hooks/useChangeParams';
import useDateSnapshot from 'hooks/useDateSnapshot';
import useFlags from 'hooks/useFlagStats/useFlags';
import { FlagType, flagTypeToContextType } from 'types';
import { Sorting } from 'types/sorting';

import Navigation from '../components/navigation';
import TeamHeader from '../components/team-header';
import useDiscrepancyCount from '../hooks/useDiscrepancyCount';
import { useGoBackToTeams } from '../hooks/useGobackToTeams';
import useNavigationStats from '../hooks/useNavigationStats';
import { useRouteFlagTypes } from '../hooks/useRouteFlagTypes';
import { useRouteScheduleType } from '../hooks/useRouteScheduleType';
import useTabFlagStats from '../hooks/useTabFlagStats';
import { useTeam } from '../hooks/useTeam';

import { TeamFlagsContainer } from './styles';

type Props = {
    route: Route<typeof groups.leagueTeamsTeamStatistics>;
};

const Team = ({ route }: Props) => {
    const statusOptions = useDiscrepancyStatusOptions(route.params.status);

    const sorting =
        'sortBy' in route.params
            ? { attribute: route.params.sortBy, order: route.params.sortOrder }
            : undefined;
    const sortingOptions = useFlagsSortingOptions(
        [FlagsSortingAttribute.DiscrepancyCount, FlagsSortingAttribute.DiscrepancyDate],
        sorting,
    );

    const { data: team, isLoading: teamIsLoading } = useTeam(route.params.id);

    const flagTypes = useRouteFlagTypes(route.name);
    const flagsQuery = useFlags({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
        contextObjectId: route.params.id,
        flagType: flagTypes.join(','),
        discrepancyStatus: route.params.status,
        ...('sortBy' in route.params && {
            sortBy: route.params.sortBy,
            sortOrder: route.params.sortOrder,
        }),
    });
    const navigationStats = useNavigationStats(route.params.league, route.params.id);
    const stats = useTabFlagStats(route.params.id, flagTypes);

    const updateDate = useDateSnapshot(flagsQuery.dataUpdatedAt);

    const discrepancyCount = useDiscrepancyCount(flagsQuery.data ?? []);

    const scheduleType = useRouteScheduleType(route.name);
    const adminScheduleMutation = useAdminScheduleMutation();

    const handleGoBackToTeams = useGoBackToTeams(route);
    const handleParamsChange = useChangeParams(route);

    const handleSortingChange = React.useCallback(
        (sorting: Sorting<FlagsSortingAttribute>) =>
            handleParamsChange({ sortBy: sorting.attribute, sortOrder: sorting.order }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (status: DiscrepancyStatusFilter) => handleParamsChange({ status }),
        [handleParamsChange],
    );

    const handleGameScheduleRefresh = React.useCallback(() => {
        adminScheduleMutation.mutate({
            league: route.params.league,
            seasonType: route.params.seasonType,
            seasonYear: route.params.seasonYear,
            id: route.params.id,
            scheduleType: scheduleType as Admin.ScheduleTypes,
        });
    }, [adminScheduleMutation, route.params, scheduleType]);

    const [flagged, ignored] =
        route.name === 'teamsTeamRoster'
            ? [discrepancyCount.created, discrepancyCount.ignored]
            : [stats.created, stats.ignored];

    if (teamIsLoading) {
        return (
            <div data-testid="loading-teams">
                <Loading mt={4} />
            </div>
        );
    }

    if (!team) {
        return <>Not Found</>;
    }

    return (
        <View route={route}>
            <TeamHeader
                isRefreshing={flagsQuery.isFetching}
                onBackButtonClick={handleGoBackToTeams}
                onRefreshClick={flagsQuery.refetch}
                team={team}
                updateDate={updateDate}
            />
            <Navigation navigationStats={navigationStats} route={route} />
            <TeamFlagsContainer data-testid="teams-flag-container">
                <FlagStats flagged={flagged} ignored={ignored}>
                    {route.name !== 'teamsTeam' && (
                        <div className="actions-container">
                            <RefreshAction>
                                {route.name !== 'teamsTeamPolls' && (
                                    <Tooltip
                                        disabled={adminScheduleMutation.isLoading}
                                        disableInteractive
                                        title="Click to update the flags"
                                    >
                                        <div>
                                            <Refresh
                                                onClick={handleGameScheduleRefresh}
                                                disabled={adminScheduleMutation.isLoading}
                                                data-testid="refresh"
                                                title="Refresh"
                                            >
                                                <IconDecorated
                                                    sizeRem={1}
                                                    name="Refresh"
                                                    schemeColor="--color-flag-stats-refresh-color"
                                                />
                                            </Refresh>
                                        </div>
                                    </Tooltip>
                                )}
                            </RefreshAction>
                            {flagTypes.map((flagType) => (
                                <FlagSystemEvents
                                    key={flagType}
                                    league={route.params.league}
                                    seasonType={route.params.seasonType}
                                    seasonYear={route.params.seasonYear}
                                    contextObjectId={route.params.id}
                                    context={flagTypeToContextType(flagType as FlagType)}
                                />
                            ))}
                        </div>
                    )}
                </FlagStats>
                <ControlPanel>
                    <ControlPanel.Group>
                        {(route.name === 'teamsTeam' ||
                            route.name === 'teamsTeamSeasonal' ||
                            route.name === 'teamsTeamSchedule' ||
                            route.name === 'teamsTeamPolls') && (
                            <Dropdown
                                label="Sort"
                                options={sortingOptions}
                                onChange={handleSortingChange}
                            />
                        )}
                        <Dropdown
                            label="Status"
                            options={statusOptions}
                            onChange={handleStatusChange}
                        />
                    </ControlPanel.Group>
                </ControlPanel>
                <FlagAccordion
                    loading={flagsQuery.isLoading}
                    flags={flagsQuery.data}
                    status={route.params.status}
                />
            </TeamFlagsContainer>
        </View>
    );
};

export default Team;
