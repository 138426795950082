import * as React from 'react';

import { Admin } from 'api/types/bff';
import ExternalLinks from 'components/external-links';
import { byName } from 'components/flag-accordion/compare-discrepancies';
import { FlagContainer } from 'components/flag-accordion/styles';
import FlagRefresh from 'components/flag-refresh';
import FlagSystemEvents from 'components/flag-system-events';
import Loading from 'components/loading';
import SourceSelector from 'components/source-selector';
import { ValuesBySource } from 'components/source-selector/types';
import useAdminScheduleMutation from 'hooks/useAdminScheduleMutation';
import useSorting from 'hooks/useSorting';
import { FlagType, flagTypeToContextType, SourceId } from 'types';

import { teamName } from '../../team-name';
import FlagContext from '../context';
import { AccordionContent } from '../default/styles';
import HeaderStandard from '../header-standard';
import { useTeamContext } from '../hooks/useTeamContext';
import { useTotalDiscrepanciesCount } from '../hooks/useTotalDiscrepanciesCount';
import DiscrepancyCategorized from '../seasonal/discrepancy-categorized';
import { FlagContentContainer, Header, SourceSelectorContainer } from '../styles';
import { FlagProps } from '../types';

const FlagPolls = ({ flag }: FlagProps) => {
    const {
        discrepancyBySources,
        activeSource,
        setActiveSource: handleSourceClick,
        activeDiscrepancies,
        areDiscrepanciesLoading,
        externalLinks,
        areExternalLinksLoading,
        isToggled,
        handleToggle,
    } = React.useContext(FlagContext);

    const sortDiscrepancies = useSorting(byName);
    const sortedDiscrepancies = sortDiscrepancies(activeDiscrepancies);

    const sourceSelectorValues: ValuesBySource = discrepancyBySources
        .map((discrepancies) => ({
            source: discrepancies.source,
            value: discrepancies.discrepancies.length,
        }))
        .filter((value) => value.source !== SourceId.SR && value.value);

    const teamContext = useTeamContext(flag);
    const getFlagHeader = React.useCallback(() => {
        if (!teamContext) {
            return 'N/A';
        }

        return `${teamName(teamContext)} - Week ${flag.week}`;
    }, [teamContext, flag.week]);

    const totalDiscrepancies = useTotalDiscrepanciesCount(flag);

    const adminScheduleMutation = useAdminScheduleMutation();
    const handleRefreshClick = React.useCallback(() => {
        adminScheduleMutation.mutate({
            league: flag.league,
            seasonType: flag.seasonType,
            seasonYear: flag.seasonYear,
            id: teamContext?.srId!,
            week: flag.week,
            scheduleType: Admin.ScheduleTypes.TeamPolls,
        });
    }, [
        adminScheduleMutation,
        flag.league,
        flag.seasonType,
        flag.seasonYear,
        flag.week,
        teamContext?.srId,
    ]);

    return (
        <FlagContainer data-testid="flag-polls-container">
            <HeaderStandard
                text={getFlagHeader()}
                handleToggle={handleToggle}
                isToggled={isToggled}
                iconName="EqualizerRounded"
                iconColor="blue400"
                flagType={flag.flagType.name}
                count={totalDiscrepancies}
                createdAt={flag.createdAt}
            />
            <AccordionContent visible={isToggled} data-testid="accordion-content">
                <FlagContentContainer>
                    <Header>
                        <div className="actions">
                            <FlagRefresh
                                onClick={handleRefreshClick}
                                isLoading={adminScheduleMutation.isLoading}
                            />
                            <ExternalLinks
                                isLoading={areExternalLinksLoading}
                                linksInfo={externalLinks}
                            />
                            <FlagSystemEvents
                                league={flag.league}
                                seasonYear={flag.seasonYear}
                                seasonType={flag.seasonType}
                                context={flagTypeToContextType(flag.flagType.key as FlagType)}
                                contextObjectId={flag.context.srId}
                            />
                        </div>
                    </Header>
                    {areDiscrepanciesLoading ? (
                        <Loading mt={2} />
                    ) : (
                        <>
                            <SourceSelectorContainer>
                                <SourceSelector
                                    valuesBySource={sourceSelectorValues}
                                    onSourceClick={handleSourceClick}
                                    activeSource={activeSource}
                                />
                            </SourceSelectorContainer>
                            <hr className="line" />
                            <DiscrepancyCategorized
                                title="Team"
                                discrepancies={sortedDiscrepancies}
                                type="team"
                            />
                        </>
                    )}
                </FlagContentContainer>
            </AccordionContent>
        </FlagContainer>
    );
};

export default FlagPolls;
